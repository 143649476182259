import styles from './Login.module.css';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import UsersFinder from '../apis/UsersFinder';

const Login = () => {
	const navigate = useNavigate();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [loginErr, setLoginErr] = useState('');
	
	const onSubmitHandler = async e => {
		
		e.preventDefault();
		
		try {
			await UsersFinder.post('/login', {username, password}, {
				header: { "Content-Type": "application/json" },
				withCredentials: true,
			});
			navigate('/mahaga')
		} catch(err) {
			setLoginErr(err?.response?.data?.msg)
		}
	};

	return (
		<form onSubmit={onSubmitHandler}>
			<div className={styles.login}>
				<div className={styles.inputGroup}>
					<input type="text" name='username' value={username} onChange={e => setUsername(e.target.value)} placeholder='Username'/>
				</div>
				
				<div className={styles.inputGroup}>
					<input type="password" name='password' value={password} onChange={e => setPassword(e.target.value)} placeholder='Password'/>
				</div>

				<div className={styles.inputGroup}>
					<button>Login</button>
				</div>
				<div className={styles.inputGroup}>
					<p className={styles.loginErr}>{loginErr}</p>
				</div>
			</div>

		</form>
	);
};

export default Login;
