import { useEffect } from 'react';
import styles from './AboutMePage.module.css';
import Main from '../../components/Main';
import { Phone, EnvelopeSimple } from "@phosphor-icons/react";
// import { SnapchatLogo } from '@phosphor-icons/react/dist/ssr';



const AboutMe = () => {
	useEffect(() => {
		document.title = 'Julius Sičiūnas | About Me';
	}, []);
	return (
		<Main>
			<picture>
  				{/* <source media="(min-width:769px)" srcSet={`./images/about_me/about-me_1_comp.jpg`} /> */}
  				{/* <source media="(min-width:428px)" srcSet={`./images/about_me/about-me_2_comp.jpg`} /> */}
  				{/*<img className={styles.aboutMeImg} src={`./images/about_me/sniper_about_me.jpg`} alt="About Me" />*/}
				<img className={styles.aboutMeImg} src={`./images/about_me/about_me.webp`} alt="About Me" />
			</picture>
			
			<div className={styles.aboutMe} style={{color: '#fff'}}>
				<div className={styles.contactItem}>
					<EnvelopeSimple size={32} className={styles.icon} />	
                	<a href="mailto:julius.siciunas@gmail.com">julius.siciunas@gmail.com</a>
				</div>

				<div className={styles.contactItem}>
					<Phone size={32} className={styles.icon}/>
					<a href="tel:+37068764764">+370 687 647 64</a>
				</div>

				
				<a className={styles.contactItem} href="./CV/Julius_Siciunas_CV_EN_2024.pdf" download>
					<img className={styles.js_cv} src="./CV/cv.png" alt='cv'/>
					<span>Download</span>
				</a>
					

				<a href="https://www.imdb.com/name/nm2947545/" target="_blank" rel="noreferrer">
					<img className={styles.imdb} src="./CV/IMDb_light.png" alt='img'/>
				</a>

				
			</div>
			
		</Main>
	);
};

export default AboutMe;