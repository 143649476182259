import styles from './Navigation.module.css';
import { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";


const Navigation = () => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	
	const navhome = window.location.pathname === '/';
	const showNav = () => {
		navigate('/');
		setOpen(false);
	};

	return (
		<nav className={`${styles.nav} ${navhome ? styles.navBackground : ''}`} >
			<div className={styles.navName} onClick={() => showNav()}>
				<h1>JULIUS SIČIŪNAS
					<span className={styles.lac}>LAC</span>
				</h1>
				<div className={styles.cinematographer}>CINEMATOGRAPHER</div>
			</div>
			<ul className={`${styles.navItems} ${styles.responsive} ${open ? styles.showNav : ''}`} onClick={() => setOpen(v => false)}>
				<li><NavLink end to='/movies'>MOVIES</NavLink></li>
				<li><NavLink end to='/commercials'>COMMERCIALS</NavLink></li>
				<li><NavLink end to='/music-videos'>MUSIC VIDEOS</NavLink></li>
				 <li><NavLink end to='/photos'>PHOTOS</NavLink></li> 
				<li><NavLink end to='/about-me'>ABOUT ME</NavLink></li>
				{/*<li><NavLink>Sign out</NavLink></li>*/}
			</ul>

			<div className={`${styles.bars} ${open ? styles.show : ''}`} onClick={() => setOpen(v => !v)}>
				<div className={`${styles.bar} ${styles.barTop}`}></div>
				<div className={`${styles.bar} ${styles.barMid}`}></div>
				<div className={`${styles.bar} ${styles.barBottom}`}></div>
			</div>
		</nav>
	);
};

export default Navigation;